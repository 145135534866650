export enum ObjectType
{
    Region = 1,
    Client,
    Portfolio,
    Fund,
    Asset,
    Loan,
    Covenant
}
